:root {
  --alertalert-50: #fcedf0;
  --alertalert-500: #dc4c64;
  --body-1-font-family: "Poppins-SemiBold", Helvetica;
  --body-1-font-size: 24px;
  --body-1-font-style: normal;
  --body-1-font-weight: 600;
  --body-1-letter-spacing: 0px;
  --body-1-line-height: normal;
  --body-2-font-family: "Poppins-Regular", Helvetica;
  --body-2-font-size: 18px;
  --body-2-font-style: normal;
  --body-2-font-weight: 400;
  --body-2-letter-spacing: 0px;
  --body-2-line-height: normal;
  --body-3-font-family: "Poppins-Regular", Helvetica;
  --body-3-font-size: 16.6px;
  --body-3-font-style: normal;
  --body-3-font-weight: 400;
  --body-3-letter-spacing: 0px;
  --body-3-line-height: normal;
  --body-bold-font-family: "Montserrat-SemiBold", Helvetica;
  --body-bold-font-size: 16px;
  --body-bold-font-style: normal;
  --body-bold-font-weight: 600;
  --body-bold-letter-spacing: 0px;
  --body-bold-line-height: 120%;
  --body-font-family: "Poppins-Regular", Helvetica;
  --body-font-size: 16px;
  --body-font-style: normal;
  --body-font-weight: 400;
  --body-letter-spacing: 0px;
  --body-line-height: 120%;
  --caption-font-family: "Poppins-Regular", Helvetica;
  --caption-font-size: 13px;
  --caption-font-style: normal;
  --caption-font-weight: 400;
  --caption-letter-spacing: 0px;
  --caption-line-height: 120%;
  --darkdark-100: #bababa;
  --darkdark-200: #999;
  --darkdark-50: #e9e9e9;
  --darkdark-500: #222;
  --foundation-failedf100: #fc6f6f;
  --foundation-failedf200: #fa3030;
  --foundation-failedf300: #f90606;
  --foundation-failedf400: #ae0404;
  --foundation-failedf50: #fee6e6;
  --foundation-failedf500: #980404;
  --foundation-failedf75: #fd9999;
  --foundation-failedf700: #980404;
  --foundation-neutralneutral-1: #fff;
  --foundation-neutralneutral-10: #262626;
  --foundation-neutralneutral-11: #1f1f1f;
  --foundation-neutralneutral-12: #141414;
  --foundation-neutralneutral-13: #000;
  --foundation-neutralneutral-2: #fcfcfc;
  --foundation-neutralneutral-3: #f5f5f5;
  --foundation-neutralneutral-4: #f0f0f0;
  --foundation-neutralneutral-5: #d9d9d9;
  --foundation-neutralneutral-6: #bfbfbf;
  --foundation-neutralneutral-7: #8c8c8c;
  --foundation-neutralneutral-8: #595959;
  --foundation-neutralneutral-9: #454545;
  --foundation-primaryp100: #737d96;
  --foundation-primaryp200: #374569;
  --foundation-primaryp300: #0e1f4a;
  --foundation-primaryp400: #0a1634;
  --foundation-primaryp50: #e7e9ed;
  --foundation-primaryp500: #09132d;
  --foundation-primaryp75: #9ca3b5;
  --foundation-secondaarys100: #9db5ce;
  --foundation-secondaarys200: #7395b9;
  --foundation-secondaarys300: #567fab;
  --foundation-secondaarys400: #3c5978;
  --foundation-secondaarys50: #eef2f7;
  --foundation-secondaarys500: #344d68;
  --foundation-secondaarys75: #bacbdd;
  --foundation-successs100: #85b271;
  --foundation-successs200: #509134;
  --foundation-successs300: #2c7b0a;
  --foundation-successs400: #1f5607;
  --foundation-successs50: #eaf2e7;
  --foundation-successs500: #1b4b06;
  --foundation-successs75: #a8c99b;
  --foundationprimaryp-200: #374569;
  --foundationprimaryp-500: #09132d;
  --heading-1-font-family: "Poppins-Regular", Helvetica;
  --heading-1-font-size: 68.8px;
  --heading-1-font-style: normal;
  --heading-1-font-weight: 400;
  --heading-1-letter-spacing: 0px;
  --heading-1-line-height: normal;
  --heading-2-font-family: "Poppins-Regular", Helvetica;
  --heading-2-font-size: 60.8px;
  --heading-2-font-style: normal;
  --heading-2-font-weight: 400;
  --heading-2-letter-spacing: 0px;
  --heading-2-line-height: normal;
  --heading-3-font-family: "Poppins-Regular", Helvetica;
  --heading-3-font-size: 52.8px;
  --heading-3-font-style: normal;
  --heading-3-font-weight: 400;
  --heading-3-letter-spacing: 0px;
  --heading-3-line-height: normal;
  --heading-4-font-family: "Poppins-Regular", Helvetica;
  --heading-4-font-size: 44.8px;
  --heading-4-font-style: normal;
  --heading-4-font-weight: 400;
  --heading-4-letter-spacing: 0px;
  --heading-4-line-height: normal;
  --heading-5-font-family: "Poppins-Regular", Helvetica;
  --heading-5-font-size: 36.8px;
  --heading-5-font-style: normal;
  --heading-5-font-weight: 400;
  --heading-5-letter-spacing: 0px;
  --heading-5-line-height: normal;
  --heading-6-font-family: "Poppins-Regular", Helvetica;
  --heading-6-font-size: 28.8px;
  --heading-6-font-style: normal;
  --heading-6-font-weight: 400;
  --heading-6-letter-spacing: 0px;
  --heading-6-line-height: normal;
  --primary-1: #0e1f4a;
  --primary-2: #193478;
  --primary-3: #52689f;
  --primaryprimary-500: #a020f0;
  --button-primary: #09132d;
  --secondarysecondary-500: #90ee90;
  --successsuccess-50: #e8f6ed;
  --successsuccess-500: #14a44d;
  --texttext-200: #b2b2b2;
  --texttext-500: #585757;
  --warningwarning-50: #fcf6e8;
  --warningwarning-500: #e4a11b;
  --whitewhite-200: #fff;
}
/*# sourceMappingURL=instant-transfer.9dcadbb8.css.map */
